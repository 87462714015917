












import {defineComponent, ref} from '@vue/composition-api'

export default defineComponent({
    name: 'About',
    setup() {
        const version = ref(process.env.VUE_APP_VERSION)
        return {
            version
        }
    },
})

